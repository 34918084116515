import type { FilterSelect } from 'egenie-utils';
import { NormalProgramme } from 'egenie-utils';
import { getWarehouseArea, getWarehouseBin } from '../../utils';
import type { Store } from './index';

export function normalProgramme(context: Store): NormalProgramme {
  return new NormalProgramme({
    filterItems: [
      {
        type: 'select',
        field: 'warehouse_id-4-1',
        label: '仓库',
        onChangeCallback: (warehouseId: string) => {
          const warehouseAreaIdItem = context.normalProgramme.filterItems.getFilterItem('warehouse_area_id-4-1') as FilterSelect;
          warehouseAreaIdItem.value = [];
          warehouseAreaIdItem.handleData([]);

          const warehouseBinIdItem = context.normalProgramme.filterItems.getFilterItem('warehouse_bin_id-4-1') as FilterSelect;
          warehouseBinIdItem.value = [];
          warehouseBinIdItem.handleData([]);

          getWarehouseArea({ warehouseId })
            .then((data) => warehouseAreaIdItem.handleData(data));
          getWarehouseBin({ warehouseId })
            .then((data) => warehouseBinIdItem.handleData(data));
        },
      },
      {
        type: 'select',
        field: 'warehouse_area_id-4-1',
        mode: 'multiple',
        label: '库区',
        onChangeCallback: (warehouseAreaId: string[]) => {
          const warehouseBinIdItem = context.normalProgramme.filterItems.getFilterItem('warehouse_bin_id-4-1') as FilterSelect;
          warehouseBinIdItem.value = [];
          warehouseBinIdItem.handleData([]);

          const warehouseId = (context.normalProgramme.filterItems.getFilterItem('warehouse_id-4-1') as FilterSelect).value as string;

          getWarehouseBin({
            warehouseAreaId: warehouseAreaId ? warehouseAreaId.join(',') : undefined,
            warehouseId,
          })
            .then((data) => warehouseBinIdItem.handleData(data));
        },
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'warehouse_bin_id-4-1',
        label: '库位',
      },
      {
        type: 'input',
        field: 'sku_no-1-2',
        label: 'SKU编码',
      },
      {
        type: 'input',
        field: 'product_name-1-4',
        label: '商品名称',
      },
      {
        type: 'input',
        field: 'seller_outer_no-1-4',
        label: '商品货号',
      },
      {
        type: 'input',
        field: 'bar_code-1-2',
        label: '条形码',
      },
      {
        type: 'inputNumberGroup',
        field: 'real_number-2-99-5',
        label: '库位库存',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'owner_id-4-2',
        label: '货主',
      },
      {
        type: 'select',
        field: 'bin_type',
        label: '库位类型',
      },
      {
        type: 'select',
        field: 'error_stock_flag',
        label: '是否异常',
        data: [
          {
            value: '1',
            label: '是',
          },
          {
            value: '0',
            label: '否',
          },
        ],
      },
    ],
    count: 6,
    handleSearch: () => context.mainSubStructureModel.onQuery(),
  });
}
