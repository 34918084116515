import { message, Modal } from 'antd';
import type { BaseData, PaginationData } from 'egenie-utils';
import { ImgFormatter, MainSubStructureModel, request } from 'egenie-utils';
import { values } from 'mobx';
import React from 'react';
import type { MainItem } from './types';
import type { Store } from './index';

export function mainSubStructureModel(context: Store): MainSubStructureModel {
  return new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'warehouse_name',
          name: '仓库',
          width: 150,
        },
        {
          key: 'warehouse_area_name',
          name: '库区',
          width: 150,
        },
        {
          key: 'warehouse_bin_no',
          name: '库位',
          width: 150,
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'product_no',
          name: '商品编码',
          width: 250,
        },
        {
          key: 'product_name',
          name: '商品名称',
          width: 250,
        },
        {
          key: 'sku_no',
          name: 'SKU编码',
          width: 250,
        },
        {
          key: 'bar_code',
          name: '条形码',
          width: 250,
        },
        {
          key: 'color_type',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size_type',
          name: '尺码',
          width: 100,
        },
        {
          key: 'spec',
          name: '规格',
          width: 150,
        },
        {
          key: 'real_number',
          name: '库位库存',
          width: 80,
        },
        {
          key: 'owner_name',
          name: '货主',
          width: 150,
        },
        {
          name: '供应商',
          key: 'vendor_no',
          width: 150,
        },
        {
          key: 'lock_number',
          name: '锁定库存',
          width: 100,
          formatter: ({ row }) => (
            <a onClick={() => context.handleLockNumberJump(row)}>
              {row.lock_number}
            </a>
          ),
        },
        {
          key: 'error_stock',
          name: '异常库存',
          width: 80,
        },
        {
          key: 'pre_out_stock_number',
          name: '预出库库存',
          width: 80,
        },
        {
          key: 'bin_type_name',
          name: '库位类型',
          width: 80,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'v_stock_id',
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/stockInventorySearch/index',
      searchReduce: true,
      searchReduceConfig: [
        {
          name: '实物库存',
          value: 0,
        },
        {
          name: 'SKU种数',
          value: 0,
        },
      ],
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
    },

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        request<BaseData<{ skuNum: number; sum: number; }>>({
          url: '/api/cloud/wms/rest/warehouse/cloud/queryStockNum',
          method: 'POST',
          data: filterParams,
        })
          .then((info) => {
            context.mainSubStructureModel.gridModel.searchReduceConfig[0].value = info?.data?.sum || 0;
            context.mainSubStructureModel.gridModel.searchReduceConfig[1].value = info?.data?.skuNum || 0;
          });

        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/warehouse/cloud/querys',
          method: 'POST',
          data: new URLSearchParams(Object.entries({
            ...rest,
            vo: JSON.stringify(filterParams),
          })),
        });
      },
    },
    hiddenSubTable: true,
    pageId: '1639',
    buttons: [
      {
        permissionId: '6',
        text: '导出',
        icon: 'icon-export',
        handleClick: () => {
          const ids = values(context.mainSubStructureModel.gridModel.selectedIds)
            .join(',');
          const fileName = '库位库存';
          const exportType = 'cloud_wms_bin';
          const queryParam = context.normalProgramme.filterItems.params;
          const queryParamShow = context.normalProgramme.filterItems.translateParams.join(' ');

          if (ids) {
            context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
      {
        permissionId: '730',
        text: '生成盘点任务',
        handleClick: () => {
          const selectRows: MainItem[] = context.mainSubStructureModel.gridModel.selectRows;
          if (selectRows.length === 0) {
            const error = '请选择数据';
            message.warn({
              key: error,
              content: error,
            });
            return;
          }

          Modal.confirm({
            content: '确定生成盘点任务?',
            onOk: async() => {
              await request({
                url: '/api/cloud/wms/rest/bill/st/by/stock',
                method: 'post',
                data: {
                  paramList: selectRows.map((item) => ({
                    skuId: item.sku_id,
                    warehouseBinId: item.warehouse_bin_id,
                  })),
                },
              });
              message.success('生成成功');
            },
          });
        },
      },
    ],
  });
}
