import { Card, Layout } from 'antd';
import type { NormalProgramme, MainSubStructureModel, BaseData } from 'egenie-utils';
import { request, MainSubStructure, NormalProgrammeComponent, ExportStore, ExportModal } from 'egenie-utils';
import qs from 'qs';
import React from 'react';
import { getOwner, getWarehouse, getWarehouseArea, getWarehouseBin, getWarehouseBinType } from '../../utils';
import styles from './index.less';
import { mainSubStructureModel } from './mainSubStructureModel';
import { normalProgramme } from './normalProgramme';
import type { MainItem } from './types';

export class Store {
  constructor() {
    this.mainSubStructureModel = mainSubStructureModel(this);

    this.normalProgramme = normalProgramme(this);

    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;

    getWarehouse()
      .then((data) => this.normalProgramme.filterItems.addDict({ 'warehouse_id-4-1': data }));
    getWarehouseArea()
      .then((data) => this.normalProgramme.filterItems.addDict({ 'warehouse_area_id-4-1': data }));
    getWarehouseBin()
      .then((data) => this.normalProgramme.filterItems.addDict({ 'warehouse_bin_id-4-1': data }));

    getOwner()
      .then((data) => this.normalProgramme.filterItems.addDict({ 'owner_id-4-2': data }));

    getWarehouseBinType()
      .then((data) => this.normalProgramme.filterItems.addDict({ bin_type: data }));
  }

  public mainSubStructureModel: MainSubStructureModel;

  public normalProgramme: NormalProgramme;

  public exportStore: ExportStore = new ExportStore({ parent: null });

  private isLockNumberJump = false;

  public handleLockNumberJump = (row: MainItem): void => {
    if (this.isLockNumberJump) {
      return;
    }

    this.isLockNumberJump = true;
    request<BaseData<string[]>>({
      url: '/api/cloud/wms/rest/warehouse/cloud/query/waybill',
      method: 'post',
      data: {
        warehouseId: row.warehouse_id,
        warehouseBinId: row.warehouse_bin_id,
        skuId: row.sku_id,
      },
    })
      .then((info) => {
        if (info?.data?.length) {
          window.top?.egenie?.openTabId(1640, qs.stringify({ courierNo: info.data.join(',') }));
        }
      })
      .finally(() => this.isLockNumberJump = false);
  };
}

export default class extends React.Component {
  public store = new Store();

  render() {
    return (
      <>
        <Layout className={styles.container}>
          <Layout.Content>
            <Card size="small">
              <NormalProgrammeComponent store={this.store.normalProgramme}/>
            </Card>
            <div className={styles.tableWrapper}>
              <MainSubStructure store={this.store.mainSubStructureModel}/>
            </div>
          </Layout.Content>
        </Layout>
        <ExportModal store={this.store.exportStore}/>
      </>
    );
  }
}
